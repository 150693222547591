import { Link } from 'react-router-dom';

import MetaTagsAdmin from './MetaTagsAdmin';

function NotFoundAdminPage() {
	return (<>
		<MetaTagsAdmin />

		<h1>Erreur 404</h1>
		<p>La page que vous avez demandé n'existe pas.</p>

		<Link to="/">Retourner au dashboard du back-office</Link>
	</>);
}

export default NotFoundAdminPage;