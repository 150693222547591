import { Helmet } from 'react-helmet-async';

const metaContent = {
	not_found: {
		title: "Back-office Images GuenoMiller"
	},
	dashboard: {
		title: "Back-office Images GuenoMiller"
	},
	importer: {
		title: "Importer | Back-office GuenoMiller"
	},
	indexation: {
		title: "Indexation | Back-office GuenoMiller"
	},
	recherche_avancee: {
		title: "Recherche avancée | Back-office GuenoMiller"
	},
	utilisateurs: {
		title: "Utilisateurs | Back-office GuenoMiller"
	}
};

function MetaTagsAdmin() {
	// Get the pathname from the current URL, excluding query parameters
	const currentPath = window.location.pathname.split('?')[0];

	// Split the path into segments
	const pathSegments = currentPath.split('/').filter(Boolean);

	// Define known static paths for your application (update this list as necessary)
	const basePaths = [
		'importer',
		'indexation',
		'recherche_avancee',
		'utilisateurs'
		// Add more paths as necessary
	];

	// Find the first segment that matches known static paths
	const currentPage = pathSegments.find(segment => basePaths.includes(segment)) || 'dashboard';

	// Generate the canonical link path
	const canonicalPath = currentPage === 'dashboard' ? '/' : `/${currentPage}`;

	// Fetch meta content based on the current page
	const pageMeta = metaContent[currentPage] || metaContent['dashboard'];

	return (
		<Helmet>
			<title>{pageMeta.title}</title>
			<meta name="description" content={pageMeta.description} />

			{/* Canonical link */}
			<link rel="canonical" href={`https://www.imagesguenomiller.com${canonicalPath}`} />
		</Helmet>
	);
};

export default MetaTagsAdmin;
