import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { TransitionRoute, AuthContextProvider } from '@client/shared';

import { ThesaurusContextProvider } from './contexts/ThesaurusAdminContext';

import RootAdmin from './components/RootAdmin';
import NotFoundAdminPage from './components/NotFoundAdminPage';
import DashboardPage from './components/DashboardPage';
import ImporterPage from './components/ImporterPage';
import IndexationPage from './components/IndexationPage';
import RechercheAvanceePage from './components/RechercheAvanceePage';
import UtilisateursPage from './components/UtilisateursPage';

const routes = [
	{
		path: "/",
		element: <DashboardPage />,
	},
	{
		path: "/importer",
		element: <ImporterPage />,
	},
	{
		path: "/indexation",
		element: <IndexationPage />,
	},
	{
		path: "/recherche_avancee",
		element: <RechercheAvanceePage />,
	},
	{
		path: "/utilisateurs",
		element: <UtilisateursPage />,
	},
	{
		path: "*",
		element: <NotFoundAdminPage />,
	},
];

const router = createBrowserRouter([
    {
        path: "/",
        element: <RootAdmin />,
        children: routes.map(route => ({
            ...route,
            element: <TransitionRoute element={route.element} />
        }))
    }
])

ReactDOM.createRoot(document.getElementById('root')).render(
	<AuthContextProvider>
		<ThesaurusContextProvider>
			<RouterProvider router={router} />
		</ThesaurusContextProvider>
	</AuthContextProvider>    
)