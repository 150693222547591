import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoClose, IoMenu, IoHeart, IoHome } from 'react-icons/io5';

import { logoVDI_thumbnail, useLogout, displayWidth, getPublicLink } from '@client/shared';

import SearchBarAdmin from './SearchBarAdmin.jsx';

import '../stylesheets/admin.css';

function HeaderAdmin() {
	const [showMenu, setShowMenu] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const { logout } = useLogout();
	
	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const closeMenuOnMobile = () => {
		if (window.innerWidth < displayWidth.medium && showMenu) {
			setShowMenu(false);
		}
	};

	const logoutHandler = () => {
		// code pour déconnecter utilisateur
		closeMenuOnMobile();
		logout();
	}

	// Automatically close the menu on mobile when the user clicks on a link
	useEffect(() => {
		closeMenuOnMobile();
		// eslint-disable-next-line
	}, [location.pathname, location.search, navigate]);

	const closeButton = (<>
		<div id="nav__close" onClick={toggleMenu}>
			<IoClose />
		</div>
	</>);

	const home = getPublicLink("/fr/");
	const search = getPublicLink("/fr/search");
	const favourites = getPublicLink("/fr/favourites");

	const admin_topnav = (<>
		{closeButton}
		<ul className='nav__list'>
			<li className='nav__item'><a href={search}>Recherche</a></li>
			<li className='nav__item'><Link to="/recherche_avancee">Recherche avancée</Link></li>
			<li className='nav__item'><Link to="/importer">Importer</Link></li>
			<li className='nav__item'><Link to="/indexation">Indexation</Link></li>
			<li className='nav__item'><Link to="/utilisateurs">Utilisateurs</Link></li>
		</ul>
		<ul className='nav__list'>
			<li className='nav__cta'><a href={home} onClick={logoutHandler}>Déconnexion</a></li>
		</ul>
	</>);

	return (
		<header className="page-header">
			<div className="container">
				<div className="logo">
					<Link to="/"><img src={logoVDI_thumbnail} alt="Logo" /></Link>
				</div>
				<div className="imagesguenomiller">
                    <p id="brand">Iconothèque Images GuenoMiller</p>
                    <p id="subtitle">Mode - Architecture - Décor - Design</p>
                </div>
				<div className="language-search">
					<SearchBarAdmin />
					<div className="icons">
						<div id="lien-dashboard">
							<Link to="/"><IoHome /></Link>
						</div>
						<div id="heart">
							<a href={favourites}><IoHeart /></a>
						</div>
					</div>
				</div>
                <nav className="topnav">
					<div className={`nav__menu ${showMenu ? "show-menu" : ""}`}>
						{admin_topnav}
					</div>
					<div id="nav__toggle" onClick={toggleMenu}>
						<IoMenu />
					</div>
                </nav>
			</div>
		</header>
	);
}

export default HeaderAdmin;