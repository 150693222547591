import MetaTagsAdmin from './MetaTagsAdmin';

function UtilisateursPage() {
	return (<>
		<MetaTagsAdmin />
		
		<h1>Gérer les utilisateurs</h1>
		<p className='construction'>Page en construction</p>
	</>);
}

export default UtilisateursPage;