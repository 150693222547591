import React, { Suspense, useEffect, useLayoutEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { Loader, useAuthContext, getPublicLink } from '@client/shared';
import '@client/shared/src/stylesheets/main.css';
import '@client/shared/src/stylesheets/modal.css';

import HeaderAdmin from './HeaderAdmin';
import FooterAdmin from './FooterAdmin';

function RootAdmin() {
	const location = useLocation();
	const navigate = useNavigate();
	const { user, authLoading } = useAuthContext();
	const redirect_link = getPublicLink("/fr/");

	// Protect page from unauthorized users
	useEffect(() => {
		if (!authLoading) { // Only run the protection logic when not loading
			if (!user || user.role !== 4) {
				window.location.assign(redirect_link);
			}
		}
		// eslint-disable-next-line
	}, [user, authLoading, location.pathname, navigate]);
	
	// Scroll to top of page after a page transition.
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
    }, [location.pathname, location.search, navigate]);

	// Conditionally render the content only if the user is authorized
    if (!user || user.role !== 4) {
        return <Loader />; // Prevents rendering of admin page content
    }

	return (<>
		<Suspense fallback={<Loader />}>
			<HelmetProvider>
				<HeaderAdmin />
				<main className="page-main">
                    <Outlet />
                </main>
				<FooterAdmin />
			</HelmetProvider>
		</Suspense>
	</>);
}

export default RootAdmin;