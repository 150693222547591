import ScrollToTop from 'react-scroll-to-top';

import { logoInstagram, logoLinkedIn, getPublicLink } from '@client/shared';

function FooterAdmin() {
	const legal_notices = getPublicLink("/fr/legal_notices");
	const terms_of_service = getPublicLink("/fr/terms_of_service");

	return (<>
		<footer className="page-footer">
			<div className="container">
				<nav className="bottomnav">
					<a href={legal_notices} rel="nofollow">Mentions légales</a>
					<a href={terms_of_service} rel="nofollow">CGU</a>
				</nav>
				<div id="copyright">
					© 2024 images GuenoMiller. Tous droits réservés.
				</div>
				<div id="reseaux">
					<div>
						<a href="https://www.instagram.com/imagesguenomiller">@imagesguenomiller</a>
						<a href="https://www.instagram.com/imagesguenomiller"><img src={logoInstagram} alt="Logo Instagram" /></a>
					</div>
					<div>
						<a href="https://www.linkedin.com/in/guenolee-milleret/">@guenolee-milleret</a>
						<a href="https://www.linkedin.com/in/guenolee-milleret/"><img src={logoLinkedIn} alt="Logo LinkedIn" /></a>
					</div>
				</div>
			</div>
			<ScrollToTop id="scroll-button" smooth={true} color="white" />
		</footer>
	</>);
}

export default FooterAdmin;