import { useEffect, useState } from 'react';
import { api, getPublicLink } from '@client/shared';

import MetaTagsAdmin from './MetaTagsAdmin';

import '../stylesheets/dashboard.css';

function DashboardPage() {
	const [stats, setStats] = useState(null);
	const [serverError, setServerError] = useState(false);
	const home = getPublicLink("/fr/");
	
	// Fetch DB stats
	useEffect(() => {
		const fetchStats = async () => {
			try {
				const response = await api.get("/admin/stats/categories");
				if (response.status === 200) {
					setStats(response.data.stats);
				}
			} catch (error) {
				setStats(null); // Clear on error
				setServerError(true);
				console.log("Error while retrieving DB stats");
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				}
			}
		};
	
		fetchStats();
	},[])

	return (<>
		<MetaTagsAdmin />
		
		<h1>Back-office | Dashboard</h1>

		<div id="lien-front-office">
			<a href={home}>Front-office | Accueil</a>
		</div>

		<div>
			<h2>Statistiques du fonds</h2>
			{serverError ?
			<p className="error-message">Une erreur est survenue lors du chargement des statistiques du fonds.</p> :
			(stats ?
			<ul>
				{Object.entries(stats).map(([category, count]) => (
                    <li key={category}>
                        <strong>{category}&nbsp;:</strong> {count} images
                    </li>
                ))}
			</ul> :
			<p>En cours de chargement…</p>)
			}
		</div>
	</>);
}

export default DashboardPage;