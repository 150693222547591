import { useRef, useState } from 'react';

import {
	getPublicLink,
	validateAnnee,
	validateDecennie,
	validateTextFormat
} from '@client/shared';

import { useThesaurusContext } from '../contexts/ThesaurusAdminContext';

function SearchBarAdmin() {
	const { thesaurus } = useThesaurusContext();

	const searchPath = getPublicLink('/fr/search');
	const searchBarRef = useRef('');
	const [error, setError] = useState(false);

	const validate = () => {
		if (!searchBarRef.current) {
			return false;
		}
		let error = !validateTextFormat(searchBarRef.current.value);
		setError(error);
		return error;
	}

	const handleChange = () => {
		setError(false);
	}

	const handleBlur = () => {
		validate();
	}

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!searchBarRef.current) {
			return;
		}

		const searchValue = searchBarRef.current.value.trim();
		const searchValueLower = searchValue.toLowerCase();

		// revalidate field format
		let error = validate();
		if (error) {
			return; // Prevent form submission
		}

		/* Search matching values in the thesaurus */
		const findThesaurusItem = (champ, options, bilingue = false) => {
			let foundItem = bilingue
            	? options.find(item => item.nom_fr.toLowerCase() === searchValueLower || item.nom_en.toLowerCase() === searchValueLower)
            	: options.find(item => item.nom.toLowerCase() === searchValueLower);
			if (foundItem) {
				// redirect to search page
				window.location.assign(`${searchPath}?${champ}=${foundItem.id}`);
				// Indicate that a match was found and redirection has occurred
				return true;
			}
			return false;
		}

		if (searchValue) {
			let queryString;

			// codeImage
			if (searchValue.match(/VDI/)) {
				queryString = `code_image=${searchValue}`;
				window.location.assign(`${searchPath}?${queryString}`);
				return;
			}

			// decennie
			if (validateDecennie(searchValue)) {
				queryString = `decennie=${searchValue}`;
				window.location.assign(`${searchPath}?${queryString}`);
				return;
			}

			// annee
			else if (validateAnnee(searchValue)) {
				queryString = `annee=${searchValue}`;
				window.location.assign(`${searchPath}?${queryString}`);
				return;
			}

			// Cas particulier sousCategorie
			let foundItem = thesaurus.sousCategories.find(item => item.nom_fr.toLowerCase() === searchValueLower || item.nom_en.toLowerCase() === searchValueLower);
			if (foundItem) {
				// redirect to search page
				window.location.assign(`${searchPath}?categorie=${foundItem.parent}&sous_categorie=${foundItem.id}`);
				return;
			}

			// ID based fields
			if (
				findThesaurusItem("source", thesaurus.sources) ||
				findThesaurusItem("categorie", thesaurus.categories, true) ||
				findThesaurusItem("mot_cle", thesaurus.motsCles, true) ||
				findThesaurusItem("style", thesaurus.styles, true) ||
				findThesaurusItem("couturier", thesaurus.couturiers) ||
				findThesaurusItem("designer", thesaurus.designers) ||
				findThesaurusItem("coloris", thesaurus.coloris, true)
			) {
				return; // Stop further execution after first match and redirect
			}

			// legende
			queryString = `legende=${searchValue}`;
			window.location.assign(`${searchPath}?${queryString}`);
		}
	}

	return (<>
		<form className="search">
			<input
				ref={searchBarRef}
				type="text"
				id="search-bar"
				placeholder="Recherche libre"
				onChange={handleChange}
				onBlur={handleBlur}
				maxLength="50"
				required
				className={error ? "search-bar-error" : ""}
			/>
			<input
				type="submit"
				id="submit-icon"
				onClick={handleSubmit} />
		</form>
	</>);
}

export default SearchBarAdmin;