import MetaTagsAdmin from './MetaTagsAdmin';

function ImporterPage() {
	return (<>
		<MetaTagsAdmin />
		
		<h1>Importer des images</h1>
		<p className='construction'>Page en construction</p>
	</>);
}

export default ImporterPage;